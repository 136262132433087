.interest__container {
  grid-template-columns: repeat(3, 350px);
  column-gap: 3rem;
  justify-content: center;
}

.interest__content {
  background-color: var(--container-color);
  border: 1px solid rgb(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  width: 350px;
}

.interest__header {
  display: flex;
  align-items: center; /* Vertically align items in the header */
}

.interest__title {
  flex: 1; /* Let the title take remaining space */
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  padding: 1rem;
  text-align: center; /* Center-align the title */
}

.interest__close-button {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
  padding: 0.5rem;
}

.interest__slider {
  width: 100%;
  height: 250px;
}

.interest__swiper-pic-warp {
  position: relative;
  width: 100%;
}

.interest__icon {
  margin-right: 3px;
  font-size: 1.2rem;
}
.interest__subtitle {
  display: grid;
  justify-content: center;

  margin-bottom: 1rem;
  margin-top: 1rem;
}

.interest__subtitle-text {
  font-size: var(--small-font-size);
  font-weight: var(--font-normal);
}

.interest__swiper-img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 180px;
  max-width: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.interest__swiper-data {
  display: grid;
  justify-items: center;
}

.interest__modal {
  display: none;
}

/* SWIPER CLASS */
.swiper-pagination-bullet {
  background-color: var(--text-color) !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--title-color) !important;
}
.interest .swiper-button-prev {
  color: var(--text-color) !important;
  margin-top: 0 !important;
  top: auto !important;
  bottom: 1rem !important;
  padding: 20px !important;
}

.interest .swiper-button-next {
  color: var(--text-color) !important;
  margin-top: 0 !important;
  top: auto !important;
  padding: 20px !important;
  bottom: 1rem !important;
}

.hidden {
  display: none;
}

.show-grid {
  display: none;
}

/*=============== BREAKPOINTS ===============*/

@media screen and (max-width: 1150px) {
  .interest__container {
    grid-template-columns: repeat(2, 350px);
    row-gap: 2rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .interest__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .interest__modal .interest__title {
    margin-left: 40px;
  }

  .show-swiper {
    display: none;
  }

  .show-grid {
    display: grid;
  }

  .interest__container {
    grid-template-columns: repeat(2, auto);
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .interest__content-small {
    width: 150px;
    cursor: pointer;
  }

  .interest__content:hover {
    background-color: aliceblue;
  }

  .interest__icon-small {
    display: block;
  }

  .interest__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    padding: 0 1rem;
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
  }
}

/* For small devices */
@media screen and (max-width: 400px) {
  .interest__title-text {
    display: none;
  }

  .interest__modal .interest__title-text {
    display: inline;
  }

  .interest__content-small {
    width: 90px;
    height: 90px;
    cursor: pointer;
    align-items: center;
  }

  .interest__title-small {
    height: 90px;
    display: grid;
    align-items: center;
  }

  .interest__icon-small {
    margin-right: 0px;
    font-size: 1.8rem;
  }
}
